<!--设备模块--设备时间表-->
<template>
  <div id="lightTimetable">
    <div class="aside">
      <a-spin :spinning="spinning" style="width: 100%; height: 100%">
        <my-navder :list="list" @select="getMsgFormSon"></my-navder>
      </a-spin>
    </div>
    <div class="right">
      <a-spin :spinning="loading" class="main-spinning" v-if="id">
        <div class="main">
          <my-headertitle>{{$t('lightRunTimetable.a29')}}</my-headertitle>
          <div class="info">
            <div>
              <span>{{$t('lightRunTimetable.a2')}}</span>
              <span v-show="initial">{{ name }}</span>
              <a-input
                v-show="alter"
                v-model="name"
                ref="input"
                class="info-input"
                size="small"
              />
                <a-icon
                  type="form"
                  v-show="initial"
                  @click="StateChanges"
                  :style="{
                    fontSize: '15px',
                    color: '#7682CE',
                    'margin-left': '15px',
                  }"
                />
                <a-button
                  v-show="alter"
                  class="info-button"
                  size="small"
                  @click="amendCancel"
                  type="primary"
                  ghost
                  >{{$t('lightRunTimetable.a3')}}</a-button
                >
                <a-button
                  v-show="alter"
                  class="info-button"
                  size="small"
                  type="primary"
                  :loading="btloaing"
                  @click="updateName"
                  >{{$t('lightRunTimetable.a4')}}</a-button
                >
            </div>    
            <div>
            <span>{{$t('lightRunTimetable.a36')+": "}}</span>
            <a-select
              v-model="calendarId"
              style="width: 150px"
              @select="calendarSelect"
            >
              <a-select-option v-for="item in calendarList" :key="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
            <a-button type="primary" @click="calendarUpdate" :loading="btloaing">{{$t('general.a2')}}</a-button>
          </div>        
          </div>
          <my-timetable
            :data="data"
            :scheType="scheType"
            :id="scheId"
            :calendarId="calendarId"
            @getData="getData"
          ></my-timetable>
        </div>
      </a-spin>
      <div class="main-show" v-else>
        <a-icon type="calendar" style="font-size: 60px; margin-bottom: 20px" />
        <span>{{$t('lightRunTimetable.a10')}}</span>
      </div>
      <div class="footer" v-if="id">
        <a-button type="primary" @click="allCopyTt">{{$t('lightRunTimetable.a11')}}</a-button>
        <a-button type="primary" @click="copyTt">{{$t('lightRunTimetable.a12')}}</a-button>
      </div>
    </div>
    <a-modal
      :title="$t('lightRunTimetable.a13')"
      :width="400"
      centered
      :visible="visible1"
      :closable="true"
      :maskClosable="false"
      :confirm-loading="confirmLoading1"
      @ok="allHandleOk"
      @cancel="allHandleCancel"
    >
      <div class="modal1">
        <span>{{$t('lightRunTimetable.a14')}}</span>
      </div>
      <a-select
        :placeholder="$t('lightRunTimetable.a10')"
        v-model="modalKey1"
        style="width: 150px"
      >
        <a-select-option v-for="item in modal1" :key="item.key">
          {{ item.title }}
        </a-select-option>
      </a-select>
    </a-modal>
    <a-modal
      :title="$t('lightRunTimetable.a12')"
      :width="600"
      centered
      :visible="visible2"
      :closable="true"
      :maskClosable="false"
      :confirm-loading="confirmLoading2"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div>
        <span>{{$t('lightRunTimetable.a15')}}</span>
        <a-select
          :placeholder="$t('lightRunTimetable.a10')"
          v-model="srcDay"
          style="width: 150px; margin: 0px 20px"
        >
          <a-select-option v-for="item in week" :key="item.value">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div>
        <span>{{$t('lightRunTimetable.a16')}}</span>
        <a-select
          :placeholder="$t('lightRunTimetable.a10')"
          v-model="beginDay"
          style="width: 150px; margin: 10px 20px"
        >
          <a-select-option v-for="item in week" :key="item.value">
            {{ item.name }}
          </a-select-option>
        </a-select>
        <span>~</span>
        <a-select
          :placeholder="$t('lightRunTimetable.a10')"
          v-model="endDay"
          style="width: 150px; margin: 10px 20px"
        >
          <a-select-option v-for="item in week" :key="item.value">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
    </a-modal>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import Navdar from "../../components/Navdar/vertical";
import headerTitle from "../../components/Title/headerTitle";
import timetableModule from "../common/schedule/timetableModule";
import { lightTimetableListKT,getLightTimetableData,updateScheduleName,getScheduleInfoDetail,copyAllSchedule,copyOneSchedule } from "../../api/device";
import {
  scheduleCalendarUpdate,
  // getDID,
  doorTtUpdateName,
  // copyAllTt,
  // copyOneTt,
  getCalendar,
} from "../../api/door";

export default {
  data() {
    return {
      name: "",
      calendarId: 0,
      calendarList: undefined,
      spinning: false,
      list: [],
      alter: false,
      initial: true,
      id: undefined,
      loading: false,
      btloaing: false,
      data: undefined,
      initialname: "",
      scheId: undefined,
      scheType: "9",
      visible1: false,
      visible2: false,
      confirmLoading1: false,
      confirmLoading2: false,
      modal1: [],
      modal2: [],
      modalKey1: undefined,
      modalKey2: undefined,
      srcDay: undefined,
      beginDay: undefined,
      endDay: undefined,
      week: [
        { name: this.$t('lightRunTimetable.a17'), value: 0 },
        { name: this.$t('lightRunTimetable.a18'), value: 1 },
        { name: this.$t('lightRunTimetable.a19'), value: 2 },
        { name: this.$t('lightRunTimetable.a20'), value: 3 },
        { name: this.$t('lightRunTimetable.a21'), value: 4 },
        { name: this.$t('lightRunTimetable.a22'), value: 5 },
        { name: this.$t('lightRunTimetable.a23'), value: 6 },
        { name: this.$t('lightRunTimetable.a24'), value: 7 },
        { name: this.$t('lightRunTimetable.a25'), value: 8 },
        { name: this.$t('lightRunTimetable.a26'), value: 9 },
        { name: this.$t('lightRunTimetable.a27'), value: 10 },
        { name: this.$t('lightRunTimetable.a28'), value: 11 },
      ],
    };
  },
  watch: {
    // 监听侧边栏选择的id，并加载页面数据
    id: function (newQuestion, oldQuestion) {
      this.loading = true;
      let number = this.id.split(",");
      this.scheId = number[1];
      let data = {
        scheId: number[1],
        scheType: this.scheType,
      };
      console.log("lightTimetable detail Par:",data);
      getScheduleInfoDetail(data)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          this.data = data;
          if(this.data!=null){
            this.name = data.name;
            this.calendarId=data.calendarId;
          }
          console.log("lightTimetable Data:",data);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
  mounted() {
  },
  activated(){
    // 进入页面时调用获取页面左侧列表方法
    this.navderList();
    this.getCalendarList();
    if(this.scheId && this.scheType){
      this.getData();
    }
  },
  methods: {
    calendarUpdate(){ 
      this.btloaing=true;  
         
      let par = {
        scheId: this.scheId,
        calendarId:this.calendarId,
      };
      console.log("calendarUpdate par:",par);
      scheduleCalendarUpdate(par)
        .then((res) => {
          let { data } = res;
          if(data){
            this.$message.success(this.$t('general.a6'));
          } else {
            this.$message.error(this.$t('general.a7'));
          }
          this.btloaing=false;
        })
        .catch((err) => {
          console.log(err);
          this.btloaing=false;
        });
    },
    getCalendarList(){
      let par = {
        siteId: this.$route.query.id,
      };
      console.log("getCalendarList par:",par);
      getCalendar(par)
        .then((res) => {
          console.log("getCalendarList res:",res);
          let { data } = res;
          this.calendarList = data;
          //在开头添加一项
          let item={
            name: "",
            value: 0
          }
          this.calendarList.unshift(item);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    calendarSelect(key) {
      for (let i = 0; i < this.calendarList.length; i++) {
        if (key == this.calendarList[i].value) {
        }
      }
    },
    // 加载页面数据的方法
    getData() {
      this.loading = true;
      let data = {
        scheId: this.scheId,
        scheType: this.scheType,
      };
      getScheduleInfoDetail(data)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          this.data = data;
          this.name = data.name;
          console.log(res.data);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 名称修改框焦点获取
    StateChanges() {
      this.initialname = this.name;
      this.initial = !this.initial;
      this.alter = !this.alter;
      setTimeout(() => {
        this.$refs.input.focus();
      }, 5);
    },
    // 取消修改名称的方法
    amendCancel() {
      this.name = this.initialname;
      this.initial = !this.initial;
      this.alter = !this.alter;
    },
    // 获取页面左侧列表方法
    navderList() {
      let name = this.$route.name;
      console.log("route name:", name);
      if(name.indexOf('FAC_SCEDULE')>=0 || name.indexOf('ENE_SCEDULE')>=0){
          this.scheType="9"; 
      }else if(name.indexOf('FAC_RUN_SCEDULE') || name.indexOf('ENE_RUN_SCEDULE')>=0){
        this.scheType="15"; 
      } else  if(name.indexOf('FAC_KIKIRENDOU')>=0){
          this.scheType="6"; 
      }
      // if( name === "VRFAC_FAC_SCEDULE" 
      //   || name === "LIGHTING_FAC_SCEDULE" 
      //   || name === "ENVIRONMENT_FAC_SCEDULE"
      //   || name === "BSDSAC_FAC_SCEDULE" 
      //   || name === "FACILITIES_FAC_SCEDULE"){
      //     this.scheType=9; 
      // }  else if( name === "VRFAC_FAC_KIKIRENDOU" 
      //   || name === "LIGHTING_FAC_KIKIRENDOU"
      //   || name === "ENVIRONMENT_FAC_KIKIRENDOU"
      //   || name === "BSDSAC_FAC_KIKIRENDOU"){
      //     this.scheType=6; 
      // }
      
      if(!this.scheType){
        return;
      }
      let par = {
        siteId: this.$route.query.id,
        schType: this.scheType,
      };
      this.spinning = true;
      this.list = [];
      console.log("lightTimetableListKT par:", par);
      lightTimetableListKT(par)
        .then((res) => {
          let { data } = res;
          data.key = "sub1";
          this.list.push(data);
          this.spinning = false;
        })
        .catch((err) => {
          this.spinning = false;
          console.log(err);
        });
    },
    // 点击侧边栏获取页面详细数据加载id
    getMsgFormSon(data) {
      this.id = data;
    },
    // 页面更新name成功后更新类表名字
    alterName(arr) {
      let id = this.id;
      let name = this.name;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].key == id) {
          arr[i].title = name;
        }
        if (arr[i].children) {
          this.alterName(arr[i].children);
        }
      }
    },
    // 获取当前页的时间表列表
    getTtList(arr) {
      let type = this.scheType;
      let id = this.scheId;
      
      if(arr!=null && arr.length>0){
        let children = arr[0].children;
        console.log("getTtList par:",children);
        if(children!=null && children.length>0){
          for (let i = 0; i < children.length; i++) {
            if (children[i].key !== undefined) {
              let _key = children[i].key.split(",")[0];
              let _id = children[i].key.split(",")[1];
              if ( _id !== id) {
                let key = children[i].key.split(",")[1];
                let title = children[i].title;
                this.modal1.push({ key, title });
              }
            }
          }
        }
      }
    },
    // 更新name
    updateName() {
      this.btloaing = true;
      let scheId = this.scheId;
      let scheType = this.scheType;
      let newName = this.name;
      let data = {
        scheId,
        scheType,
        newName,
      };
      // console.log(data);
      doorTtUpdateName(data)
        .then((res) => {
          this.btloaing = false;
          // console.log(res);
          if (res.errorCode == "00") {
            this.initial = !this.initial;
            this.alter = !this.alter;
            this.alterName(this.list);
            this.$message.success(res.msg);
          } else if (res.errorCode == "02") {
            this.$message.error(res.msg);
            this.amendCancel();
          }
        })
        .catch((err) => {
          this.btloaing = false;
          this.amendCancel();
          console.log(err);
        });
    },
    // 时间表复制(整表复制)
    allCopyTt() {
      this.visible1 = true;
      this.modal1 = [];
      this.modalKey1 = undefined;
      if(this.list!=null && this.list.length>0)
        this.getTtList(this.list[0].children);
    },
    // 时间表复制(整表复制)确定按钮
    allHandleOk() {
      if(this.calendarId==0){//日历未设置会失败
        this.$message.error(this.$t('lightRunTimetable.a37'));
        return;
      }
      console.log("calendarId value:",this.calendarId);
      this.confirmLoading1 = true;
      let srcId = this.modalKey1;
      let targetId = this.scheId;
      let scheType = this.scheType;
      let data = {
        srcId,
        targetId,
        scheType,
      };
      console.log(data);
      copyAllSchedule(data)
        .then((res) => {
          if (res.errorCode == "00") {
            this.confirmLoading1 = false;
            this.visible1 = false;
            this.getData();
            this.$message.success(res.msg);
          } else if (res.errorCode == "02") {
            this.confirmLoading1 = false;
            this.$message.error(res.msg);
          }
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // // 时间表复制(整表复制)取消按钮
    allHandleCancel() {
      this.visible1 = false;
    },
    // 时间表复制(表内复制)
    copyTt() {
      this.visible2 = true;
    },
    // 时间表复制(表内复制)确定按钮
    handleOk() {
      this.confirmLoading2 = true;
      let _b = this.beginDay;
      let _e = this.endDay;
      if (_b < _e || _b == _e) {
        let data = {
          schId: this.scheId,
          srcDay: this.srcDay,
          beginDay: this.beginDay,
          endDay: this.endDay,
          scheType: this.scheType,
        };
        console.log(data);
        copyOneSchedule(data)
          .then((res) => {
            // console.log(res);
            if (res.errorCode == "00") {
              this.confirmLoading2 = false;
              this.handleCancel();
              this.getData();
              this.$message.success(res.msg);
            } else if (res.errorCode == "02") {
              this.confirmLoading2 = false;
              this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$error({
          title: this.$t('lightRunTimetable.a30'),
          centered: true,
          onOk: () => {},
        });
      }
    },
    // 时间表复制(表内复制)取消按钮
    handleCancel() {
      this.visible2 = false;
      this.srcDay = undefined;
      this.beginDay = undefined;
      this.endDay = undefined;
    },
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-navder": Navdar,
    "my-headertitle": headerTitle,
    "my-timetable": timetableModule,
  },
};
</script>
<style scoped>
#lightTimetable {
  width: 100%;
  height: 100%;
  display: flex;
}
.right {
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
}
.aside {
  width: 250px;
  height: 100%;
  overflow: auto;
}
.main {
  width: 100%;
  height: 100%;
  padding: 0px 5px;
  overflow: auto;
}
.main-spinning {
  width: 100%;
  height: 100%;
  padding: 0px 20px 20px 20px;
  overflow: auto;
}
.main-show {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: rgba(12, 12, 12, 0.3);
  letter-spacing: 5px;
  white-space: nowrap;
}
.info {
  width: 100%;
  height: 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
}
.info-input {
  width: 120px;
  margin-right: 15px;
}
.templatetitle-right {
  display: flex;
  align-items: center;
  margin: 0;
}
.templatetitle-right li {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.upright {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.info-button {
  width: 80px;
  margin: 0px 10px;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
}
button {
  /* width: 120px; */
  margin: 0px 10px;
}
.modal1 {
  padding-bottom: 15px;
}
</style>