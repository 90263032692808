<!--门禁模块--门禁时间表-->
<template>
  <div id="timetablemodule">
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a29") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.monday"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.monday, $t('doortimetable.a29'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a30") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.tuesday"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.tuesday, $t('doortimetable.a30'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a31") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.wednesday"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.wednesday, $t('doortimetable.a31'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a32") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.thursday"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.thursday, $t('doortimetable.a32'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a33") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.friday"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.friday, $t('doortimetable.a33'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a34") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.saturday"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.saturday, $t('doortimetable.a34'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a35") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.sunday"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.sunday, $t('doortimetable.a35'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <div class="timetablemodule-row" v-if="9==scheType">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a36") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.holiday"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.holiday, $t('doortimetable.a36'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <div class="timetablemodule-row" v-if="9==scheType">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a37") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.specialDay1"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.specialDay1, $t('doortimetable.a37'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <div class="timetablemodule-row" v-if="9==scheType">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a38") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.specialDay2"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.specialDay2, $t('doortimetable.a38'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <div class="timetablemodule-row" v-if="9==scheType">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a39") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.specialDay3"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.specialDay3, $t('doortimetable.a39'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <div class="timetablemodule-row" v-if="9==scheType">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a40") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.specialDay4"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.specialDay4, $t('doortimetable.a40'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <my-dialog
      :visible="visible"
      :data="dialogData"
      :name="data.name"
      :scheType="scheType"
      :week="week"
      :scheId="id"
      :calendarId="calendarId"
    ></my-dialog>
  </div>
</template>

<script>
import showTime from "./showTime";
import deviceScheduleDialog from "./deviceScheduleDialog";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    scheType: {
      type: String,
    },
    id: {
      type: String,
    },
    calendarId:{
      type: Number,
    }
  },
  data() {
    return {
      visible: false,
      dialogData: {},
      week: "",
    };
  },
  methods: {
    // 改变对话框状态的方法
    closeDialog() {
      this.visible = false;
    },
    // 加载页面数据的方法
    getData() {
      this.$emit("getData");
    },
    // 点击修改icon时加载对应的数据和修改对话框状态
    getTime(data, week) {
      if(this.calendarId==0){
        this.$message.error(this.$t('doortimetable.a44'));
        return;
      }
      console.log("Schedule data:",data);
      this.visible = true;
      this.dialogData = data;
      this.week = week;
    },
  },
  components: {
    "my-showtime": showTime,
    "my-dialog": deviceScheduleDialog,
  },
};
</script>

<style>
#timetablemodule {
  width: 100%;
}
.timetablemodule-row {
  width: 100%;
  height: 90px;
  display: flex;
  margin-bottom: 15px;
  border: 1px solid #fff;
  box-shadow: 0 0px 5px #bdbcbc;
  border-radius: 3px;
}
.timetablemodule-row-one {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-right: 1px solid #e7e8e8;
}
.timetablemodule-row-two {
  width: calc(100% - 180px);
  display: flex;
  align-items: center;
  padding: 0px 30px;
}
.timetablemodule-row-three {
  width: 70px;
  display: flex;
  align-items: center;
}
</style>